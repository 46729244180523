import { gql } from "@apollo/client";

export const LIST_PICKERS = gql`
    query Pickers {
        pickers {
            id
            initials
            firstname
            lastname
            birthdate
            street
            housenumber
            postcode
            city
            phone
            email
            bsn
            bankaccount
        }
    }
`;
