import React from "react";
import { useMutation } from "@apollo/react-hooks";
import { Redirect } from "react-router-dom";
import { SIGNUP_PICKER } from "../../lib/graphql/mutations";
import {
    SignupPicker as SignupPickerData,
    SignupPickerVariables
} from "../../lib/graphql/mutations/SignupPicker/__generated__/SignupPicker";
import { Layout, Input, DatePicker, Typography, Form, Button } from "antd";

import moment from "moment";

import IBAN from "iban";
import { validateBSN } from "elfproef";
import { postcodeValidator } from "postcode-validator";

import { displayErrorMessage, displaySuccessNotification } from "../../lib/utils";

const { Content } = Layout;
const { Text, Title } = Typography;
const { Item } = Form;


export const SignupForm = () => {
    console.log("here we are");
    const [ formData ] = Form.useForm();

    const [ signupPicker, { loading, data } ] = useMutation<
        SignupPickerData,
        SignupPickerVariables
    >(SIGNUP_PICKER, {
        onCompleted: () => {
            displaySuccessNotification(
                "Je aanmelding is verwerkt! Je krijgt een email ter bevestiging (kan in spam belanden)."
            );
        },
        onError: () => {
            displayErrorMessage(
                "Er gaat iets mis bij je aanmelding. Probeer het later nog eens. Bij blijvende fout graag contact opnemen!"
            );
        }
    });

    const handleFinish = async (values: any) => {
        const input = {
            ...values,
            birthdate: values["birthdate"].format(),
            signup_date: moment().toISOString()
        };

        signupPicker({
            variables: {
                input
            }
        });
    };

    if (loading) {
        return (
            <Content className="signup-content">
                <div className="signup__form-header">
                    <Title level={3} className="signup__form-title">
                        Je aanmelding wordt verwerkt.
                    </Title>
                    <Text type="secondary">
                        Een momentje alsjeblieft.
                    </Text>
                </div>
            </Content>
        );
    }

    if (data && data.signupPicker) {
        return <Redirect to="/signup/thankyou" />;
    }

    return (
        <Content className="signup-content">
            <Form layout="vertical" form={formData} onFinish={handleFinish}>
                <div className="signup__form-header">
                    <Title level={3} className="signup__form-title">
                        Leuk dat je je komt opgeven voor het bessenplukken deze zomer!
                    </Title>
                    <Text type="secondary">
                        In dit formulier verzamelen we de gegevens die we nodig hebben om met je aan de slag te gaan.
                    </Text>
                </div>

                <Item
                    name="initials"
                    label="Voorletters"
                    rules={[{required: true, message: "Voorletters vereist."}]}
                    extra="Alle voorletters op je identiteitskaart/paspoort"
                >
                    <Input placeholder="A.B.C." />
                </Item>
                <Item
                    name="firstname"
                    label="Voornaam"
                    rules={[{required: true, message: "Voornaam vereist."}]}
                    extra="Hoe word je genoemd"
                >
                    <Input placeholder="Voornaam" />
                </Item>
                <Item
                    name="lastname"
                    label="Achternaam"
                    rules={[{required: true, message: "Achternaam vereist."}]}
                >
                    <Input placeholder="Achternaam" />
                </Item>
                <Item
                    name="birthdate"
                    label="Geboortedatum"
                    rules={[{required: true, message: "Geboortedatum vereist."}]}
                    extra="N.B.: Je moet minimaal 13 jaar oud zijn om bij ons te mogen werken!"
                >
                    <DatePicker format="DD/MM/YYYY" />
                </Item>
                <Item label="Adres">
                    <Input.Group>
                        <Item
                            name="street"
                            label="Straatnaam"
                            rules={[{required: true, message: "Straatnaam vereist."}]}
                        >
                            <Input placeholder="Straatnaam" />
                        </Item>
                        <Item
                            name="housenumber"
                            label="Huisnummer"
                            rules={[{required: true, message: "Huisnummer vereist."}]}
                        >
                            <Input placeholder="Huisnummer" />
                        </Item>
                    </Input.Group>
                </Item>
                <Item
                    name="postcode"
                    label="Postcode"
                    rules={[
                        {
                            required: true,
                            message: "Postcode vereist."
                        },
                        ({ getFieldValue }) => ({
                            validator(rule, value) {
                                if (!value || postcodeValidator(getFieldValue("postcode"), "NL")) {
                                    return Promise.resolve();
                                }
                                return Promise.reject("Deze postcode is ongeldig.");
                            }
                        })
                    ]}
                    normalize={value => value.replace(/\s+/g, "").toUpperCase()}
                >
                    <Input placeholder="1234AA" />
                </Item>
                <Item
                    name="city"
                    label="Plaats"
                    rules={[{required: true, message: "Plaatsnaam vereist."}]}
                >
                    <Input placeholder="Plaats" />
                </Item>
                <Item
                    name="email"
                    label="E-mail"
                    rules={[{required: true, message: "E-mailadres vereist."}]}
                >
                    <Input placeholder="E-mailadres" />
                </Item>
                <Item
                    name="phone"
                    label="Mobiel telefoonnummer"
                    rules={[
                        {
                            required: true,
                            message: "Telefoonnummer vereist."
                        },
                        ({ getFieldValue }) => ({
                            validator(rule, value) {
                                if (!value || (getFieldValue("phone").length == 10 && /^\d+$/.test(getFieldValue("phone")))) {
                                    return Promise.resolve();
                                }
                                return Promise.reject("Het telefoonnummer moet uit exact 10 cijfers bestaan.");
                            }
                        })
                    ]}
                >
                    <Input placeholder="Telefoonnummer" />
                </Item>
                <Item
                    name="bsn"
                    label="Burgerservicenummer"
                    rules={[
                        {
                            required: true,
                            message: "Burgerservicenummer vereist."
                        },
                        ({ getFieldValue }) => ({
                            validator(rule, value) {
                                if (!value || validateBSN(getFieldValue("bsn"))) {
                                    return Promise.resolve();
                                }
                                return Promise.reject("Dit burgerservicenummer is ongeldig.");
                            }
                        })
                    ]}
                >
                    <Input placeholder="BSN" />
                </Item>
                <Item
                    name="bankaccount"
                    label="IBAN"
                    rules={[
                        {
                            required: true,
                            message: "IBAN vereist."
                        },
                        ({ getFieldValue }) => ({
                            validator(rule, value) {
                                if (!value || IBAN.isValid(getFieldValue("bankaccount"))) {
                                    return Promise.resolve();
                                }
                                return Promise.reject("Dit IBAN is ongeldig.");
                            }
                        })
                    ]}
                    normalize={value => value.toUpperCase()}
                >
                    <Input placeholder="IBAN" />
                </Item>

                <Item>
                    <Button type="primary" htmlType="submit">Geef je op</Button>
                </Item>
            </Form>
        </Content>
    );
};
