import { gql } from "@apollo/client";

export const ME_QUERY = gql`
    query Me {
        me {
            name
            email
        }
    }
`;

export const LOGIN_MUTATION = gql`
    mutation Login($email: String!, $password: String!) {
      loginUser(input: {email: $email, password: $password}) {
        accessToken
        refreshToken
      }
    }
`;

export const SIGNUP_MUTATION = gql`
    mutation Signup($email: String!, $name: String!, $password: String!) {
        signupUser(input: {email: $email, name: $name, password: $password}) {
        accessToken
        refreshToken
        }
    }
`;
