import { message, notification } from "antd";

export const displaySuccessNotification = (message: string, description?: string) => {
    return notification["success"]({
        message,
        description,
        placement: "topLeft",
        style: {
            marginTop: 50
        }
    });
};

export const displayErrorMessage = (error: string) => {
    return message.error(error);
};

export const displayDate = (date: Date) : string => {
    return Intl.DateTimeFormat("nl-NL", {
        year: "numeric",
        month: "long",
        day: "2-digit"
    }).format(date);
}
