import React, { FunctionComponent } from "react";

import { useQuery, useMutation } from "@apollo/react-hooks";

import { ME_QUERY, LOGIN_MUTATION, SIGNUP_MUTATION } from "../../graphql/User";
import { Login as LoginData, LoginVariables } from "../../graphql/User/__generated__/Login";
import { Me as MeData } from "../../graphql/User/__generated__/Me";
import { Signup as SignupData, SignupVariables } from "../../graphql/User/__generated__/Signup";

import { saveTokens, deleteTokens } from "../../lib/auth/tokens";

export interface IAuthContext {
    user: MeData | undefined;
    signup: ((variables: SignupVariables) => Promise<void>);
    login: ((variables: LoginVariables) => Promise<void>);
    logout: (() => void);
}
const AuthContext = React.createContext<IAuthContext>({} as IAuthContext);

export const useAuthState = () => {
    const context = React.useContext(AuthContext);
    if (context === undefined) {
        throw new Error("useAuthState must be used within an AuthProvider");
    }

    return context;
}

export const AuthProvider : FunctionComponent = ({ children }) => {
    const { loading, data: user, refetch } = useQuery<MeData>(
        ME_QUERY,
        { errorPolicy: "none" }
    );
    const [loginMutation] = useMutation<LoginData, LoginVariables>(
        LOGIN_MUTATION
    );
    const [signupMutation] = useMutation<SignupData, SignupVariables>(
        SIGNUP_MUTATION,
    );
    const logout = async () => { deleteTokens(); await refetch(); }

    const login = async (variables: LoginVariables) => {
        const { data: result } = await loginMutation({ variables });
        if (!result || !result.loginUser) {
            throw new Error("Could not log in.");
        }
        saveTokens(result.loginUser);
        await refetch();
    }

    const signup = async (variables: SignupVariables) => {
        const { data: result } = await signupMutation({ variables });
        if (!result || !result.signupUser) {
            throw new Error("Could not sign up.");
        }
        saveTokens(result.signupUser);
        await refetch();
    }

    if (loading) {
        return <p>Loading</p>;
    }

    return (
        <AuthContext.Provider value={{user, signup, login, logout}}>
            {children}
        </AuthContext.Provider>
    );
}
