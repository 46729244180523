import React from "react";
import { MenuItem } from "./Menu";

import { TeamOutlined } from "@ant-design/icons";

export const menuData: MenuItem[] = [
    {
        name: "pickers",
        label: "Plukkers",
        icon: <TeamOutlined />,
        children: [
            {
                name: "pickers-list",
                label: "Lijst plukkers",
                route: "/admin/pickers",
            }
        ]
    }
]
