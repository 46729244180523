import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useAuthState, IAuthContext } from "../../contexts/auth";
import { displayErrorMessage, displaySuccessNotification } from "../../lib/utils";
import { Form, Button, Card, Input, Layout, Space, Typography } from "antd"
import { EyeInvisibleOutlined, EyeTwoTone, UserOutlined, LockOutlined } from '@ant-design/icons';

const { Content } = Layout
const { Title } = Typography

export const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const history = useHistory();
    const location = useLocation();

    const { from } = location.state as any || { from: { pathname: "/admin" } };

    const { login } = useAuthState() as IAuthContext;

    const handleLogin = async () => {
        login({ email, password }).then(() =>
            {
                displaySuccessNotification("Succesvol ingelogd.");
                history.replace(from);
            }
        ).catch(() =>
            { displayErrorMessage("Login mislukt."); }
        );
    }

    return (
        <Content className="login">
            <Card className="login__card">
                <div className="login__card__intro">
                    <Title level={3} className="login__card__intro-title">
                        Gebr. Hueting Admin
                    </Title>
                </div>
                <Space direction="vertical">
                    <Form
                        onFinish={handleLogin}
                    >
                        <Input
                            placeholder="Email"
                            prefix={<UserOutlined />}
                            onChange={(event) => { setEmail(event.target.value) }} />
                        <Input.Password
                            placeholder="Wachtwoord"
                            iconRender={visible => (visible ? <EyeTwoTone/> : <EyeInvisibleOutlined />)}
                            prefix={<LockOutlined />}
                            onChange={(event) => { setPassword(event.target.value) }}
                        />
                        <Button type="primary" className="login__card__submit" htmlType="submit">
                            <span className="login__card__submit-text">Login</span>
                        </Button>
                    </Form>
                </Space>
            </Card>
        </Content>
    )
}
