import React from "react";
import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom";
import { Layout, Menu as AntMenu } from "antd";
import { UserOutlined } from "@ant-design/icons"

import { Menu } from "./Menu";
import { ListPickers } from "./Picker";

import { useAuthState } from "../../contexts/auth";
import { Me as MeData } from "../../graphql/User/__generated__/Me";

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = AntMenu;

export const Admin = () => {
    const { path } = useRouteMatch();

    const { user, logout } = useAuthState();

    const userMenu = ((user as MeData).me !== null) ? 
        <SubMenu key="user" icon={<UserOutlined />} title={(user as MeData).me!.name}>
            <AntMenu.Item key="user:logout" onClick={logout}>Log uit</AntMenu.Item>
        </SubMenu>
        : null;

    return (
        <Layout>
            <Sider breakpoint="lg" collapsedWidth="0">
                <Menu />
            </Sider>
            <Layout className="admin-layout">
                <Header className="admin-layout__header">
                    <AntMenu theme="dark" mode="horizontal" style={{ float: "right" }}>
                        {userMenu}
                    </AntMenu>
                </Header>
                <Content className="admin-layout__content">
                    <Switch>
                        <Route exact path={path}>
                            <Redirect to={`${path}/pickers`} />
                        </Route>
                        <Route path={`${path}/pickers`}>
                            <ListPickers />
                        </Route>
                    </Switch>
                </Content>
                <Footer style={{ textAlign: 'center' }}>Gebr. Hueting © 2020</Footer>
            </Layout>
        </Layout>
    )
}
