import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { Button, Breadcrumb, Table } from "antd"
import { CopyOutlined } from "@ant-design/icons"

import { LIST_PICKERS } from "../../../graphql/Picker";
import { GENERATE_LABELS } from "../../../graphql/Labels";
import { GENERATE_FORMS, GENERATE_TALLY } from "../../../graphql/Form";
import { Pickers as PickersData, Pickers_pickers as PickerData } from "../../../graphql/Picker/__generated__/Pickers";
import { GenerateLabels as GenerateLabelsData, GenerateLabelsVariables } from "../../../graphql/Labels/__generated__/GenerateLabels";
import { GenerateForms as GenerateFormsData, GenerateFormsVariables } from "../../../graphql/Form/__generated__/GenerateForms";
import { GenerateTally as GenerateTallyData, GenerateTallyVariables } from "../../../graphql/Form/__generated__/GenerateTally";

import { displayDate, displaySuccessNotification } from "../../../lib/utils"
import { getTokens } from "../../../lib/auth/tokens"

export const ListPickers = () => {
    const { data, loading } = useQuery<PickersData>(LIST_PICKERS);
    const [ selectedPickers, setSelectedPickers ] = useState<React.Key[]>([]);

    const [ generateLabelsGQL, { loading: loadingLabels, data: dataLabels } ] = useMutation<
        GenerateLabelsData,
        GenerateLabelsVariables
    >(GENERATE_LABELS, {
        onCompleted: () => {
            const tokens = getTokens();
            const headers = {
                "x-access-token": tokens.accessToken,
                "x-refresh-token": tokens.refreshToken,
                "Content-Type": "application/pdf"
            }

            fetch(window.location.protocol + "//" + window.location.host + "/getLabels", { headers })
            .then((response) => response.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL(
                    new Blob([blob])
                );
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "etiketten.pdf");
                document.body.appendChild(link);
                link.click();
                link.parentNode!.removeChild(link);
            });
        }
    });

    const [ generateFormsGQL, { loading: loadingForms, data: dataForms } ] = useMutation<
        GenerateFormsData,
        GenerateFormsVariables
    >(GENERATE_FORMS, {
        onCompleted: () => {
            const tokens = getTokens();
            const headers = {
                "x-access-token": tokens.accessToken,
                "x-refresh-token": tokens.refreshToken,
                "Content-Type": "application/pdf"
            }

            fetch(window.location.protocol + "//" + window.location.host + "/getForms", { headers })
            .then((response) => response.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL(
                    new Blob([blob])
                );
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "aok_formulieren.pdf");
                document.body.appendChild(link);
                link.click();
                link.parentNode!.removeChild(link);
            });
        }
    });

    const [ generateTallyGQL, { loading: loadingTally, data: dataTally } ] = useMutation<
        GenerateTallyData,
        GenerateTallyVariables
    >(GENERATE_TALLY, {
        onCompleted: () => {
            const tokens = getTokens();
            const headers = {
                "x-access-token": tokens.accessToken,
                "x-refresh-token": tokens.refreshToken,
                "Content-Type": "application/pdf"
            }

            fetch(window.location.protocol + "//" + window.location.host + "/getTally", { headers })
            .then((response) => response.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL(
                    new Blob([blob])
                );
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "plukturf.pdf");
                document.body.appendChild(link);
                link.click();
                link.parentNode!.removeChild(link);
            });
        }
    });

    const copyToClipboard = (text: string) => {
        navigator.clipboard.writeText(text);
        displaySuccessNotification("Tekst gekopieerd.");
    }

    const PickerTableColumns = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id"
        },
        {
            title: "Voornaam",
            dataIndex: "firstname",
            key: "firstname",
            sorter: (a: PickerData, b: PickerData) => a.firstname.localeCompare(b.firstname)
        },
        {
            title: "Achternaam",
            dataIndex: "lastname",
            key: "lastname",
            sorter: (a: PickerData, b: PickerData) => a.lastname.localeCompare(b.lastname)
        },
        {
            title: "Volledige naam",
            key: "fullname",
            render: (text: string, record: PickerData) => {
                const fullName = `${record.initials} ${record.lastname}`;
                return <>{fullName} <a onClick={() => { copyToClipboard(fullName); }}><CopyOutlined /></a></>
            }
        },
        {
            title: "Geboortedatum",
            dataIndex: "birthdate",
            key: "birthdate",
            render: (date: string) => displayDate(new Date(date))
        },
        {
            title: "E-mailadres",
            dataIndex: "email",
            key: "email",
            render: (email: string) => {
                return <>{email} <a onClick={() => { copyToClipboard(email); }}><CopyOutlined /></a></>
            },
            sorter: (a: PickerData, b: PickerData) => a.email.localeCompare(b.email)
        },
        {
            title: "Rekeningnummer",
            dataIndex: "bankaccount",
            key: "bankaccount",
            render: (account: string) => {
                return <>{account} <a onClick={() => { copyToClipboard(account); }}><CopyOutlined /></a></>
            },
            sorter: (a: PickerData, b: PickerData) => a.bankaccount.localeCompare(b.bankaccount)
        }

    ];

    const pickers = data ? data.pickers : null;

    const generateLabels = () => {
        generateLabelsGQL({
            variables: {
                ids: selectedPickers.map(x => Number(x))
            }
        });
    }
    
    const generateForms = () => {
        generateFormsGQL({
            variables: {
                ids: selectedPickers.map(x => Number(x))
            }
        });
    }

    const generateTally = () => {
        generateTallyGQL({
            variables: {
                ids: selectedPickers.map(x => Number(x))
            }
        });
    }

    const controls = pickers ? (
        <>
            <Button 
                onClick={generateLabels}
                type="primary"
                style={{ marginBottom: 12 }}
            >Maak etiketten</Button>
            <Button 
                onClick={generateForms}
                type="primary"
                style={{ marginBottom: 12 }}
            >Maak formulieren</Button>
            <Button 
                onClick={generateTally}
                type="primary"
                style={{ marginBottom: 12 }}
            >Maak turflijst</Button>
        </>
    ) : null;

    const pickersList = pickers ? (
        <Table
            dataSource={pickers}
            columns={PickerTableColumns}
            rowKey="id"
            rowSelection={{
                type: "checkbox",
                getCheckboxProps: (record) => ({
                    disabled: false,
                    name: "picker_" + record.id
                }),
                onChange: (selectedRowKeys) => {
                    setSelectedPickers(selectedRowKeys);
                }
            }}
        />
    ) : null;

    if (loading) {
        return <h2>Loading...</h2>
    }

    return (
        <>
            <Breadcrumb style={{ padding: "24px 24px 0px 24px" }}>
                <Breadcrumb.Item>Plukkers</Breadcrumb.Item>
                <Breadcrumb.Item>Lijst plukkers</Breadcrumb.Item>
            </Breadcrumb>
            <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                { controls }
                { pickersList }
            </div>
        </>
    )
}
