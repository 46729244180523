import React from "react";

import { Layout, Typography } from "antd";

const { Content } = Layout;
const { Text, Title } = Typography;

export const ThankYou = () => {
    return (
        <Content className="signup-content">
            <div className="signup__form-header">
                <Title level={3} className="signup__form-title">
                    Dankjewel! Je aanmelding wordt verwerkt.
                </Title>
                <Text type="secondary">
                    Je krijgt van ons een bevestigingsemail. <strong>Als je deze niet ontvangt, kijk dan in je spambox!</strong> We zien er naar uit je te ontmoeten!
                </Text>
            </div>
        </Content>
    );
}
