import React from "react";
import { Link, useLocation } from "react-router-dom";
import { pathToRegexp } from "path-to-regexp"

import { Menu as AntMenu } from "antd";

import { menuData } from "./menuData";

const { SubMenu } = AntMenu

interface NonLeafMenuItem {
    name: string;
    label: string;
    icon?: React.ReactNode;
    children: MenuItem[];
}

interface LeafMenuItem {
    name: string;
    label: string;
    icon?: React.ReactNode;
    route: string;
    template?: string;
}

export type MenuItem = NonLeafMenuItem | LeafMenuItem;

const isNonLeaf = (item: MenuItem): item is NonLeafMenuItem => "children" in item;
const isLeaf = (item: MenuItem): item is LeafMenuItem => "route" in item;

const generateMenus = (data: MenuItem[]) => {
    return data.map(item => {
        if (isNonLeaf(item)) {
            return (
                <SubMenu
                    key={item.name}
                    title={item.label}
                    {...(item.icon ? {icon: item.icon} : {})}
                >
                    {generateMenus(item.children)}
                </SubMenu>
            )
        } else {
            return (
                <AntMenu.Item
                    key={item.name}
                    {...(item.icon ? {icon: item.icon} : {})}
                >
                    <Link to={item.route}>{item.label}</Link>
                </AntMenu.Item>
            )
        }
    });
}

const findMenu = (data: MenuItem[], path: string) : MenuItem | null => (
    data.reduce<MenuItem | null>((accum, item) => {
        if (accum) return accum;
        if (isLeaf(item)) {
            const template = (item.template !== undefined) ? item.template : item.route;
            if (pathToRegexp(template).exec(path)) return item;
        }
        if (isNonLeaf(item)) return findMenu(item.children, path);

        return null;
    }, null)
)

const findPathHelper = (node: MenuItem, name: string) : MenuItem[] | undefined => {
    if (isLeaf(node) && node.name === name) return [node];
    if (isNonLeaf(node))
    {
        for (const child of node.children) {
            const childPath = findPathHelper(child, name);
            if (Array.isArray(childPath)) {
                childPath.unshift(node);
                return childPath;
            }
        }
    }
}

const findPath = (data: MenuItem[], name: string) : MenuItem[] => {
    const rootNode: NonLeafMenuItem = {
        name: "root",
        label: "",
        children: data
    }

    const path = findPathHelper(rootNode, name);

    return path ? path.slice(1): [];
}

export const Menu = () => {
    const location = useLocation();

    const currentMenu = findMenu(menuData, location.pathname);
    const selectedKeys = currentMenu ?
        findPath(menuData, currentMenu.name).map(item => item.name) : [];

    return (
        <AntMenu theme="dark" mode="inline" selectedKeys={selectedKeys}>
            {generateMenus(menuData)}
        </AntMenu>
    );
}
