import { gql } from "@apollo/client";

export const GENERATE_FORMS = gql`
    mutation GenerateForms($ids: [Int!]) {
        generateForms(ids: $ids)
    }
`

export const GENERATE_TALLY = gql`
    mutation GenerateTally($ids: [Int!]) {
        generateTally(ids: $ids)
    }
`
