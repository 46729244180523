const TOKEN_KEY = "authTokens";

interface Tokens {
    accessToken: string
    refreshToken: string
}

export const saveTokens = (tokens: Tokens) => {
    localStorage.setItem(TOKEN_KEY, JSON.stringify(tokens));
}

export const getTokens = () => {
    const jsonTokens = localStorage.getItem(TOKEN_KEY);
    if (jsonTokens) {
        return JSON.parse(jsonTokens);
    } else {
        return null;
    }
}

export const deleteTokens = () => {
    localStorage.removeItem(TOKEN_KEY);
}
