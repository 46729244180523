import React from "react";
import { useHistory } from "react-router-dom";

import { Layout, Typography, Button } from "antd";

const { Content } = Layout;
const { Text, Title } = Typography;

export const Welcome = () => {
    const history = useHistory();

    return (
        <div className="welcome">
            <div className="welcome__banner">
                <Content className="welcome__banner-content">
                    <Title level={1} className="welcome__banner-content-title">GEBR. HUETING</Title>
                    <Title level={2} className="welcome__banner-content-subtitle">Fruitteeltbedrijf<br/>Nekkerweg 25a, Zuidoostbeemster</Title>
                </Content>
            </div>
            <div className="welcome__main">
                <Content className="welcome__main-content">
                    <div className="welcome__main-content-block">
                        <Title level={1} className="welcome__main-content-block-title">Kom je bij ons bessenplukken deze zomer?</Title>
                        <Text>
                        Deze zomer wordt er weer volop bessengeplukt. Heb je voor 20 juli de leeftijd van 13 jaar bereikt? Dan mag je bij ons komen plukken. We zien er naar uit je te verwelkomen!
                        </Text>
                        <Button className="subscribe-btn" onClick={ () => { history.push("/signup"); }}>Meld je aan!</Button>
                    </div>
                </Content>
            </div>
        </div>
    );
}
