import React from "react";
import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom";

import { SignupForm } from "./SignupForm";
import { ThankYou } from "./ThankYou";

export const Signup = () => {
    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route exact path={path}>
                <Redirect to={`${path}/form`} />
            </Route>
            <Route path={`${path}/form`}>
                <SignupForm />
            </Route>
            <Route path={`${path}/thankyou`}>
                <ThankYou />
            </Route>
        </Switch>
    )
}
