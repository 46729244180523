import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';

import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { ApolloProvider } from "@apollo/react-hooks";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { Layout } from "antd";

import { Signup, Welcome, Admin, Login } from "./sections";
import { getTokens, saveTokens } from "./lib/auth/tokens";
import { PrivateRoute } from "./lib/auth/privateRoute";
import { AuthProvider } from "./contexts/auth";

import "./styles/index.css";

const authFetch = async(uri: string, options: any) => {
    const initialRequest = await fetch(uri, options);
    const { headers } = initialRequest;
    const accessToken = headers.get("x-access-token");
    const refreshToken = headers.get("x-refresh-token");
    if (accessToken && refreshToken) {
        saveTokens({ accessToken, refreshToken });
    }
    return initialRequest;
}

const httpLink = createHttpLink({
    uri: "/api",
    fetch: authFetch
});

const authLink = setContext(async (_, { headers }) => {
    const tokens = getTokens();
    if (tokens && tokens.accessToken) {
        headers = {
            ...headers,
            "x-access-token": tokens.accessToken,
            "x-refresh-token": tokens.refreshToken
        }
    }

    return { headers }
});

const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
});

const App = () => {
    return (
        <Router>
            <Layout id="app">
                <Switch>
                    <Route exact path="/" component={Welcome} />
                    <Route path="/signup" component={Signup} />
                    <Route path="/login" component={Login} />
                    <PrivateRoute path="/admin">
                        <Admin />
                    </PrivateRoute>
                </Switch>
            </Layout>
        </Router>
    )
}

ReactDOM.render(
    <ApolloProvider client={client}>
        <AuthProvider>
            <App />
        </AuthProvider>
    </ApolloProvider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
