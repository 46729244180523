import React, { FunctionComponent } from "react";
import { Route, RouteProps, Redirect } from "react-router-dom";

import { useAuthState } from "../../contexts/auth";

export const PrivateRoute: FunctionComponent<RouteProps> = ({ children, ...rest }) => {
    const { user } = useAuthState();

    return <Route {...rest} render={({ location }) =>
        (user && user.me != null) ? (
            children 
        ) : (
            <Redirect to={{
                pathname: "/login",
                state: { from: location }
            }} />
        )}
    />
}
